<template>
  <v-row justify="center" v-if="show">
    <v-col cols="12">
      <v-form ref="form" v-model="valid" @submit="submit" id="form-new-user">
        <h2 class="custom-title-secondary mb-4">
          Para finalizar, precisamos que crie sua senha para acessar o sistema
          de forma segura
        </h2>
        <v-row>
          <v-col cols="12">
            <v-text-field
              id="form-user-name"
              label="Nome Completo"
              v-model="form.name"
              :rules="rules.fullName"
              required
              validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
             id="form-user-phone"
              label="Telefone com DDD"
              :rules="rules.phone"
              v-mask="['(##) #####-####', '(##) ####-####']"
              v-model="form.phone"
              required
              validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              id="form-user-email"
              ref="email"
              label="E-mail"
              v-model="form.email"
              :rules="rules.email"
              :change="changedEmail()"
              required
              validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              @paste.prevent
              @copy.prevent
              validate-on-blur
              ref="emailConfirm"
              label="Confirme seu e-mail"
              v-model="emailConfirm"
              :rules="rules.emailConfirm"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-0 pb-0">
            <span class="custom-normal-text">
              A senha deve ter no mínimo 8 caracteres.
            </span>
            <br />
            <span class="text--secondary">
              Para uma senha segura, acrescente: 1 número, 1 letra maiúscula, 1
              letra minúscula e 1 caractere especial.
            </span>
          </v-col>
          <v-col cols="12">
            <inputPassword            
              v-model="form.password"
              :hasConfirmation="false"
              :password="{ label: 'Digite a senha' }"
              :passwordConfirm="{ label: 'Confirme a Nova Senha' }"
            ></inputPassword>
          </v-col>
        </v-row>
        <base-agree-checkbox
          label="Li e aceito os termos de Política de privacidade"
          modalTitle="Política de Privacidade - Simplificador"
          :rules="[(v) => !!v || 'Você deve aceitar os termos e condições.']"
          v-model="form.agree"
        >
          <privace-police />
        </base-agree-checkbox>
        <v-col class="text-center">
          <v-btn
            id="new-user-form"
            class="custom-btn-green rounded-0 btn-large"
            @click="submit"
            :loading="this.$store.state.newAccount.loading"
          >
            Enviar
          </v-btn>
        </v-col>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { fullName } from '@src/services/rules'
import inputPassword from '@src/components/changePassword/inputPassword.vue'
import privacePolice from '@src/components/contracts/PrivacePolice.vue'

export default {
  components: {
    inputPassword,
    privacePolice,
  },
  data() {
    return {
      show: false,
      loading: false,
      valid: false,
      form: {
        name: '',
        phone: '',
        email: '',
        password: '',
        agree: false,
      },
      emailConfirm: '',
      visibilityPassword: true,
      rules: {
        fullName,
        email: [
          (v) =>
            !this.$store.state.newAccount.emailAlreadyInUse ||
            'Esse email já está cadastrado',
          (v) =>
            /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            'Email precisa ser válido.',
          (v) => !!v || 'Por favor preencha o email.',
        ],
        emailConfirm: [
          (v) =>
            this.form.email === v ||
            'A confirmação da e-mail precisa ser igual ao e-mail',
          (v) => !!v || 'Por favor preencha o email.',
        ],
        phone: [
          (v) =>
            v.length > 9 || 'O número de telefone precisa ter mais 10 digitos.',
          (v) => !!v || 'Por favor preencha o telefone.',
        ],
      },
    }
  },

  methods: {
    changedEmail() {
      this.$store.commit('newAccount/setEmailAvailable')
    },
    submit() {
      this.form.phone = this.$options.filters.unmaskText(this.form.phone)
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('newAccount/newCreateUser', {
            dataUser: this.form,
          })
          .then((user) => {
            this.$ga.event({
              eventCategory: 'conversion',
              eventAction: 'new cadastro usuario',
              eventLabel: 'new cadastro',
              eventValue: 1,
            })
            this.$router.push({ name: 'resume' })
          })
          .catch((error) => {
            if (
              !!error.errors.email &&
              error.errors.email[0] === 'The email has already been taken.'
            ) {
              this.$store.commit('newAccount/setEmailAlreadyInUse')
              this.$refs.email.validate()
            }
          })
      }
    },
  },
  async created() {
    this.show = false
    const formUser = await this.$store.dispatch('formDataUser/getFormUser')
    this.form.name = formUser.name
    this.form.email = formUser.email
    this.show = true
  },
}
</script>
