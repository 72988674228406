<template>
  <div>
    <v-text-field
      v-bind="$attrs.password || null"
      v-model="valuePasswordLocal"
      :rules="passwordRules"
      :append-icon="visibilityPassword ? 'visibility' : 'visibility_off'"
      @click:append="() => (visibilityPassword = !visibilityPassword)"
      :type="visibilityPassword ? 'password' : 'text'"
      required
      validate-on-blur
      @blur="forceValidate()"
    ></v-text-field>
    <v-text-field
      ref="passwordConfirm-input"
      v-if="hasConfirmation"
      :disabled="!hasConfirmation"
      v-bind="$attrs.passwordConfirm || null"
      v-model="passwordConfirm"
      :append-icon="visibilityConfirmPassword ? 'visibility' : 'visibility_off'"
      @click:append="
        () => (visibilityConfirmPassword = !visibilityConfirmPassword)
      "
      :rules="passwordConfirmRule"
      :type="visibilityConfirmPassword ? 'password' : 'text'"
      required
      validate-on-blur
    ></v-text-field>
    <v-row class="mb-4">
      <v-col
        :class="$vuetify.breakpoint.smAndUp ? 'align-center ' : 'pt-0'"
        cols="12"
      >
        <span>Força da senha: {{ passwordStrength }}</span>
        <div>
          <v-chip
            class="mr-1"
            x-small
            label
            :color="verifyLowPassword() ? '#FF5722' : ''"
          >
            <span class="mx-2"></span>
          </v-chip>
          <v-chip
            class="mr-1"
            x-small
            label
            :color="verifyMeanPassword() ? '#FF9800' : ''"
          >
            <span class="mx-2"></span>
          </v-chip>
          <v-chip
            class="mr-1"
            x-small
            label
            :color="verifyStrongPassword() ? '#8BC34A' : ''"
          >
            <span class="mx-2"></span>
          </v-chip>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    hasConfirmation: {
      type: Boolean,
      required: false,
      default: true,
    },
    clearInput: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialogInterno: true,
      visibilityPassword: true,
      passwordConfirm: '',
      visibilityConfirmPassword: true,
      passwordRules: [
        (v) => !!v || 'Por favor preencha a senha',
        (v) => !this.isPasswordBlocked(v) || 'Senha inválida',
        (v) =>
          this.verifyLowPassword(v) || 'A senha precisar ter 6 ou mais letras.',
      ],
      passwordConfirmRule: [
        (v) =>
          v === this.valuePasswordLocal ||
          'Por favor preencha com a mesma senha ',
      ],
    }
  },
  computed: {
    passwordStrength() {
      if (this.verifyStrongPassword()) return 'Forte'
      if (this.verifyMeanPassword()) return 'Moderada'
      if (this.verifyLowPassword()) return 'Fraca'
      return ''
    },
    valuePasswordLocal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    forceValidate() {
      this.$refs['passwordConfirm-input']?.validate()
    },
    range(start, end) {
      let state = start
      let rangerOfNumber = ''
      if (typeof start === 'number') {
        while (state < end) {
          rangerOfNumber += String(state++)
        }
        return rangerOfNumber
      }
      let rangerOfAlphabet = Array(end)
        .fill()
        .map((item, index) => String.fromCharCode(state.charCodeAt() + index))
        .join('')
      return rangerOfAlphabet
    },
    isLessThan(value) {
      return this.valuePasswordLocal.length < value
    },
    verifyLowPassword() {
      if (this.isLessThan(6)) return false
      return true
    },
    verifyMeanPassword() {
      if (this.isLessThan(8)) return false
      return true
    },
    verifyStrongPassword(value = null) {
      value = value || this.valuePasswordLocal
      if (!this.verifyLowPassword(value)) return false
      if (!this.verifyMeanPassword(value)) return false
      if (this.isPasswordBlocked(value)) return false
      if (value.length < 10) return false
      if (!this.hasSpecialAndLowerCaseAndUperCaseCharacter(value)) {
        return false
      }
      return true
    },
    hasSpecialAndLowerCaseAndUperCaseCharacter(password) {
      return /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%¨&*.])/.test(password)
    },
    isPasswordBlocked(password) {
      const [firstDigit] = password
      let length =
        this.valuePasswordLocal.length <= 9 ? this.valuePasswordLocal.length : 9
      let equally = Array(password.length)
        .fill(firstDigit)
        .join('')
      let sequencial = this.range(firstDigit, length)
      let sequencialReverse = sequencial
        .split('')
        .reverse()
        .join('')
      let blocked = [
        'senha',
        'senha123',
        'senha1234567',
        this.range(0, 6),
        this.range(1, 6),
        this.range(1, 9),
        this.range(0, 10),
        sequencial,
        sequencialReverse,
        equally,
      ]
      return blocked.includes(password.toLowerCase())
    },
  },
}
</script>

<style lang="scss" scoped></style>
